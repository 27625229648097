<template>
  <section>
    <v-container>
      <v-row>
        <v-col lg="9" md="8">
          <div class="my-7">
            <span class="friendly-3">All our Scheduled Trips</span>
          </div>
          <v-card v-for="(trip, index) in trips" :key="index" class="mb-10" elevation="5">
            <v-carousel hide-delimiters :cycle="true" :show-arrows="false" class="mx-auto mt-4" style="height: 400px;">
              <section  v-for="(image, index) in trip.images" :key="index">
                <v-carousel-item :src="image.url"></v-carousel-item>
              </section>
              <v-overlay :absolute="true" :opacity="0.8" z-index="2">
                <div class="pa-10 text-center">
                  <div class="friendly-4 pb-4">
                    {{ trip.name }}
                  </div>
                  <div class="friendly-1">
                    <div v-if="trip.dates.length === 1">
                      <div>{{ trip.dates[0].nights }} night trip</div>
                      <div class="mt-2">{{ $moment(trip.dates[0].start).format('LL') }} to {{ $moment(trip.dates[0].end).format('LL') }}</div>
                    </div>
                    <div v-else>
                      Multiple dates available
                    </div>
                  </div>
                  <div class="friendly-1 mt-6 font-weight-bold" v-if="trip.isFilled">
                    <span class="ml-2">Fully booked!</span>
                  </div>
                  <div class="mt-6">
                    <v-btn outlined large @click="goToTrip(trip.id)">
                      Learn more
                    </v-btn>
                  </div>
                </div>
              </v-overlay>
            </v-carousel>
          </v-card>
        </v-col>
        <v-col lg="3" md="4">
          <div class="stickyTripsPanel mb-10">
            <v-card v-if="nextThreeTrips.length >= 1" class="rounded-lg mb-5 hidden-sm-and-down" elevation="10">
              <v-card-title>
                <span class="friendly-2">Upcoming Trips</span>
              </v-card-title>
              <v-card-text>
                <div v-for="(trip, index) in nextThreeTrips" :key="index">
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ trip.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                        <span v-if="trip.dates.length === 1">
                          <div>{{ $moment(trip.dates[0].start).format('LL') }} to {{ $moment(trip.dates[0].end).format('LL') }}</div>
                        </span>
                          <span v-else>
                          Multiple dates available
                        </span>
                        </v-list-item-subtitle>
                        <v-list-item-action-text>
                          <p v-snip="{ lines: 3, mode: 'js', midWord: false }">
                            {{ trip.subtitle }}
                          </p>
                        </v-list-item-action-text>
                        <v-btn block color="primary" @click="goToTrip(trip.id)">
                          Learn More
                        </v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-card-text>
            </v-card>
            <v-card class="rounded-lg" elevation="10">
              <v-card-text class="pa-8">
                <v-btn color="error" block @click="doOpenExternalUrl('https://cyborgpirate.com/dive-charter-schedule')">
                  <font-awesome-icon :icon="['fas', 'ship']" class="mr-2"/>
                  <span>AZ boat dives</span>
                </v-btn>
                <v-card class="mt-2">
                  <v-card-text>
                    <v-sheet>
                      Sea2Sea Scuba's favorite local boat dives at Lake Pleasant can found online at cyborgpirate.com.
                    </v-sheet>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <div v-if="!isLoading && trips.length === 0" class="text-center expanded">
        <div class="my-12">It seems there are no trips available</div>
        <v-btn outlined width="300" color="primary" class="mt-2" @click="doCallBusiness">
          <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
          <span class="ml-2">Call for trips</span>
        </v-btn>
      </div>
    </v-container>
  </section>
</template>

<script>

export default {
  name: 'Trips',
  data: () => ({
    isLoading: false,
    trips: []
  }),
  computed: {
    nextThreeTrips () {
      return this.trips.slice(0, 3)
    }
  },
  methods: {
    doLoad () {
      this.isLoading = true

      this.$services.trip.doGetAllActive().then(trips => {
        this.trips = trips
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    goToTrip (id) {
      this.$router.push({ name: 'view-trip', params: { id: id } })
    }
  },
  mounted () {
    this.doLoad()
  },
  created () {
    this.doSetDocumentTitle('Scheduled Trips', true)
  }
}
</script>

<style scoped>
  .stickyTripsPanel {
    position: -webkit-sticky;
    position: sticky;
    top: 92px;
  }
</style>
